import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { LoginComponent } from './features/autenticacao/login/login.component';
import { ConviteUsuarioComponent } from './features/conta-corrente/convite-usuario/convite-usuario.component';
import { AuthGuard } from './shared/guard/authguard';

const routes: Routes = [


  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/feature.module').then((m) => m.FeatureModule),
      },
    ],
  },

  {
    path: 'convite-usuario',
    component: ConviteUsuarioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'authentication/error',
  },
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
